import React, { FC } from 'react';

interface Props {

}

const NotFound: FC<Props> = () => {
	return (
		<h1>404, NotFound</h1>
	);
};

export default NotFound;
